import Vue from 'vue';
import vueComponents from './vue-components';

/****************/
/*  COMPONENTS  */
/****************/

import MessagingButton    from 'vue-app/mailboxer/messaging-button.vue';
import ActionBar          from 'vue-app/mailboxer/action-bar.vue';
import ConversationList   from 'vue-app/mailboxer/conversation-list.vue';
import ContactList        from 'vue-app/mailboxer/contact-list.vue';
import ConversationViewer from 'vue-app/mailboxer/conversation-viewer.vue';

vueComponents
.directive('ngvMessagingButton', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('MessagingButton', MessagingButton));
}])

.directive('ngvActionBar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ActionBar', ActionBar));
}])

.directive('ngvConversationList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConversationList', ConversationList));
}])

.directive('ngvContactList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ContactList', ContactList));
}])

.directive('ngvConversationViewer', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConversationViewer', ConversationViewer));
}]);
