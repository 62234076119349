import { DateTime } from 'luxon';
import { findIndex, reduce } from 'lodash';
import Timeslot from 'services/marketplace/timeslot.js';

class Availability {
  // Takes the value of the `times` key in the structure below and returns an
  // array of timeslots, with luxon times based on the times in the original object
  //
  // {
  //   times : {
  //     "2023-12-14": ["22:00", "22:30"],
  //     "2023-12-15": ["15:30", "16:00", "16:30", "17:00"],
  //     "2023-12-16": ["14:00", "15:00", "15:30", "14:30", "23:00", "22:00", "18:30"]
  //   }
  // }
  //
  // The times are assumed to be in UTC
  static load(timeslotData, otherData) {
    const timeslots = reduce(timeslotData, (array, times, date) => {
      const dateObj = DateTime.fromISO(date, { zone: 'UTC' });

      times.forEach((time) => {
        const timeComponents = time.split(':');
        const hour           = parseInt(timeComponents[0]);
        const minutes        = parseInt(timeComponents[1]);

        const timeForSlot    = dateObj.set({ hour: hour, minutes: minutes });

        array.push(new Timeslot(timeForSlot));
      });

      return array;
    }, []);

    return new this({ ...otherData, times: timeslots });
  }

  constructor(data = {}) {
    this.timeslots = [];
    this.note = (data.note || '');
    this.userSchedule = data.userSchedule;

    (data.times || []).forEach((time) => { this.add(time); });
  }

  add(timeslot) {
    if (this.includes(timeslot)) { return; }
    if (![0, 30].includes(timeslot.time.minute)) { return; }

    this.timeslots.push(timeslot);
  }

  remove(timeslot) {
    const index = findIndex(this.timeslots, ts => ts.equals(timeslot));
    this.timeslots.splice(index, 1);
  }

  timesByDate() {
    let utcTimeslot;
    let date;

    return reduce(this.timeslots, (obj, timeslot) => {
      utcTimeslot = timeslot.inZone('UTC');
      date = utcTimeslot.time.toFormat('y-LL-dd');

      obj[date] = obj[date] || [];
      obj[date].push(utcTimeslot.hour());

      return obj;
    }, {});
  }

  availableSelectedTimes() {
    return this.timeslots.filter(ts => this.isAvailable(ts));
  }

  includes(timeslot) {
    return this.timeslots.some(ts => ts.equals(timeslot));
  }

  isAvailable(timeslot) {
    if (this.userSchedule) {
      return !timeslot.isPast() && this.userSchedule.availableAt(timeslot);
    }
    else {
      return !timeslot.isPast();
    }
  }

  // TODO: if a selected time has become unavailable, this pretends it's not selected.
  // It might be nice to show them that an unavailable time was previously selected
  // (and actually remove it from what gets submitted)
  isSelected(timeslot) {
    return this.availableSelectedTimes().some(ts => ts.equals(timeslot));
  }

  // TODO: are times ever populated here or can we just use an empty array?
  validAvailability() {
    return new this.constructor({
      times: this.availableSelectedTimes(),
      note: this.note,
      userSchedule: this.userSchedule
    });
  }
}

export default Availability;
