<template>
  <priori-modal
    ref="modal"
    modal-id="scout-tag-delete-modal"
    title="Delete Tag">
    <template #modal-trigger="modalProps">
      <a href="" @click.prevent="modalProps.openModal()"><svg-icon name="trash" class="base-icon remove-tag-icon"></svg-icon></a>
    </template>

    <template #default>
      <div class="size-text-16px bottom-20">
        Delete this tag?
      </div>

      <div class="size-text-14px bottom-30">
        Note: Deleting a law firm tag permanently removes it from the law firm tags list, as well as all law firm profiles the tag was associated with.
      </div>

      <loading-section name="deleteTag">
        <button type="button" class="primary-button-compact right-20" @click.prevent="deleteTag">Yes</button>
        <button type="button" class="secondary-button-compact" @click.prevent="closeModal">Cancel</button>
      </loading-section>
    </template>
  </priori-modal>
</template>

<script>
import ScoutTag from 'vue-app/scout/resources/scout-tag.js';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import NotificationService from 'vue-app/shared/services/notification-service';

export default {
  name: 'ScoutTagDeleteModal',

  components: {
    LoadingSection,
    PrioriModal
  },

  props: {
    scoutTag: {
      type:     Object,
      required: true
    },

    onDelete: {
      type:     Function,
      required: true
    }
  },

  methods: {
    deleteTag() {
      LoadingService.loading('deleteTag');
      ScoutTag.delete({ id: this.scoutTag.id })
      .then(() => { this.onDelete(); })
      .catch(error => { NotificationService.error(`Unable to save changes: ${error}`); })
      .finally(() => {
        this.closeModal();
        LoadingService.done('deleteTag');
      });
    },

    closeModal() {
      this.$refs.modal.dismissModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .remove-tag-icon {
    width: 16px;
    height: 16px;
    fill: $k-blue;
  }
</style>
