<template>
  <priori-modal ref="modal" :title="title" :modal-size="modalSize" :backdrop="backdrop" @on-modal-open="onOpenModal" @on-modal-close="reset">
    <template #modal-trigger="{ openModal }">
      <slot name="modal-trigger" :open-modal="openModal">
        <a href="" class="normal-weight size-text-13px" @click.prevent="openModal" data-testid="open-edit-modal">{{ prompt }}</a>
      </slot>
    </template>

    <template #default="{ closeModal }">
      <validation-observer v-slot="{ handleSubmit, failed }" ref="validationObserver">
        <form role="form" name="form" class="a-form" novalidate @submit.prevent>
          <div class="bottom-30">
            <slot name="default" :input-copy="inputCopy"></slot>
          </div>

          <div class="row tight-columns">
            <div class="col-xs-4">
              <loading-button name="editModalSubmit" lb-class="primary-btn-blue" @lb-click="handleSubmit(save)">{{ saveButtonText }}</loading-button>
            </div>

            <div class="col-xs-3">
              <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
            </div>
          </div>

          <div class="error-text top-10" v-if="(failed && hasErrorSlot) || hasResponseErrors">
            <div class="size-text-12px">
              <slot name="errorMessage"></slot>
              <span v-if="hasResponseErrors">{{ responseErrorsDisplay }}</span>
            </div>
          </div>
        </form>
      </validation-observer>
    </template>
  </priori-modal>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { ValidationObserver } from 'vee-validate';
import { cloneDeep } from 'lodash';

export default {
  name: 'EditModal',

  components: {
    LoadingButton,
    PrioriModal,
    ValidationObserver
  },

  mixins: [
    modalToggle
  ],

  props: {
    input: {
      type: [Object, Array, String, Number],
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onCancel: {
      type: Function,
      default: () => {}
    },

    prompt: {
      type: String,
      default: 'Edit'
    },

    title: {
      type: String,
      required: true
    },

    backdrop: {
      type: Boolean,
      default: false
    },

    modalSize: {
      type: String,
      default: 'md'
    },

    saveButtonText: {
      type: String,
      default: 'Save'
    }
  },

  data() {
    return {
      inputCopy: undefined,
      copyBuilt: false,
      responseErrors: []
    };
  },

  computed: {
    hasErrorSlot() {
      return this.$slots.errorMessage;
    },

    hasResponseErrors() {
      return this.responseErrors.length > 0;
    },

    responseErrorsDisplay() {
      return this.responseErrors.join('; ');
    }
  },

  beforeMount() {
    this.buildEditCopy();
  },

  methods: {
    buildEditCopy() {
      this.inputCopy = cloneDeep(this.input);
    },

    onOpenModal() {
      this.buildEditCopy();

      this.openModal();
    },

    save() {
      this.responseErrors = [];
      LoadingService.loading('editModalSubmit');

      const result = this.onSave(this.inputCopy);

      Promise.resolve(result)
        .then(() => {
          this.$refs.modal.closeModal();
          this.closeModal();
          this.$refs.validationObserver.reset();
        })
        .catch((response) => {
          this.responseErrors = (response?.response?.data?.errors || []);
        })
        .finally(() => {
          LoadingService.done('editModalSubmit');
        });
    },

    reset() {
      // emit on-modal-close event further up the stack.
      this.closeModal();
      this.$refs.validationObserver.reset();
      this.responseErrors = [];
      this.onCancel();
    }
  }
};
</script>
